import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner} from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`

function PointSaleSolutionsPage() {
  return (
    <Layout pageName="technology">
      <SEO
        title="Strutured Low Voltage Cabling for Data and Voice Technologies"
        description="Data and voice require low voltage cabling to flow. Get your office equipment and phones connected with low voltage cabling with WYN Technologies for the best speed and reliable communication."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Structured Low Voltage Cabling for Data and Voice</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Structured Low Voltage Cabling for Data and Voice</GroceryTitle>
              <p>We’ve all seen the litany of cables that often occupy server rooms and business networks. Their existence can be the very lifeblood of a company. But not all these cables have been created equal. Many are divided into categories based on speed and bandwidth capabilities, but did you know that they are also segregated based on their voltage?</p>

              <p>High voltage cables provide power to various objects like switches, heat pumps, and heavier infrastructure, while low voltage cabling facilitates things that require a smaller power supply to function. Regular office accessories like phone systems, printers, and fax machines are great examples of equipment that only requires lower voltage cabling.</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">What We Offer with Our Structured Wiring, Network Cabling Services, & Other Related Services</GroceryIndustryTitle>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/placholder.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Voice and Data Cabling Installation & Technical Consultation</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                <p>A voice/data cabling network is really the lifeblood of most modern businesses. And <strong>WYN</strong> specializes in the design and installation of structured cabling systems to provide a reliable, effective telecommunications infrastructure for all sorts of businesses and commercial facilities. </p>
               
                <p>WYN uses the best cabling product solutions, but we do more than that. We’ll partner with you to design the perfect network system for your business, and we have the know-how and experience to get it installed for you correctly the first time. We take the quality of our workmanship seriously! We’ll make sure you understand everything about how your network is set up, too.</p>
                    {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/placholder.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">Business Telephone Systems & VoIP Solutions</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Looking for the best business phone system to meet your needs? <strong>WYN</strong> can help you cut costs versus an older analog or site-based PBX system by switching to a cloud-based phone system. We have many, many attractive VoIP phone system options to offer, and one of them is bound to be perfect for you! And here’s another clear advantage of choosing a VoIP solution – you won’t be required to purchase and maintain a lot of expensive hardware. Most VoIP phone products are essentially plug-and-play! Plus, you’ll enjoy lots of bonus features with VoIP that you simply won’t get anywhere else.</p>
                   {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
          <GroceryIndustryList>
              <GroceryIndustryListLeft className="industry-left">
                <StaticImage
                  src="../images/placholder.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Industry"
                  placeholder="blurred"
                />
              </GroceryIndustryListLeft>
              <GroceryIndustryListRight className="industry-right">
                <GroceryIndustryListRightTitle className="h3">WiFi Access Points & Heat Mapping Services</GroceryIndustryListRightTitle>
                <GroceryIndustryListRightContent>
                  <p>Setting up a wireless network is really another necessity for modern businesses. Not only is having WiFi convenient, practical, and important for providing the right customer experience; it can also be configured with maximum security protocols. Thankfully, <strong>WYN</strong> has plenty of experience installing Wireless Access Points (WAPs) for businesses and commercial entities, and we know all the ins and outs when it comes to wireless network setup. We can also leverage wireless heat mapping to help identify any current dead spots or weak signal points that might need addressing. When it comes to wireless networks, if you need it, we can do it!</p>
                   {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
                </GroceryIndustryListRightContent>
              </GroceryIndustryListRight>
            </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              {/* <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle> */}
              {/* <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
              <MarkdownContent>
                  <h2>Why is Structured Cabling Important?</h2>
                <p>Low voltage cabling is vital for most businesses because they’re the cables that interconnect an office’s infrastructure. And without the need for a master electrician, many companies opt for a DIY approach to minimize costs. However, this choice often leads to bigger headaches than if the company were to do its due diligence in the first place. And with structured cabling being the predominant networking method, it doesn’t take long before you’re buried beneath a blanket of blue, gray, and yellow cables.</p>
               
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Why is Structured Cabling Better than Traditional P2P Cabling?</h2>
                  <p>What is the difference between structured cabling and Point-to-Point (P2P) cabling systems? With a P2P system, users plug their computers, phones, and various pieces of hardware directly into a modem or server. A P2P system might work for a smaller office environment, but what happens when your business suddenly needs to scale up? With a P2P cabling setup, you’ll quickly discover yourself surrounded by too many devices and insufficient infrastructure to handle it!</p>

                  <p>Conversely, structured cabling is the practice of organizing your low voltage wiring by type and purpose into one packaged unit designed to maximize high-speed connectivity. Networking cables come in numerous sizes with different capabilities! And your business will most likely need several other classes of cables to handle your needs. These can range from traditional network cables to fiber-optic and coaxial cables depending on demand. Additionally, low voltage structured cabling follows industry networking standards and regulations to provide a wide blanket of efficiency security and flexibility for necessary upgrades.</p>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>What’s the Difference Between High and Low Voltage Cabling?</h2>
                <p>High voltage cables are cables used to transmit power at high voltages throughout a facility, generally to electrical fixtures, switches, and power outlets. However, with any high voltage wire, there is the inherent risk of shock or electrocution. And due to the dangerous nature of these cables, there are very stringent, specific regulations in place. As with any high voltage wiring, you will always want to have these cables installed by a licensed or master electrician.</p>

                <p>But Low voltage cabling, often referred to as structured wiring, is electrical wiring utilizing lower currents to supply power to office infrastructures like fire alarms, internet routers, phones, and intercom systems. These wires are under far less scrutiny when it comes to inspections due to the reduced electrocution risks found in high voltage types.</p>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Low Voltage Wiring - What You’ll Need to Know</h2>
                  <p>Many contractors are making the installation of structured cabling for newer construction projects a standard practice or offering the setup as an option in the home building process.</p>
                
                  <p>Structured low voltage cabling differs from standard electrical wiring because it carries a lower current than the ones used to power switches or power outlets in your home. Low voltage wiring is designed to carry 50 volts or less while standard cabling runs 120 to 240 volts and is often used for alarm systems, landscape accessories and lighting, thermostats, and even smart doorbells.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Structured Low Voltage Cabling and How WYN Technologies Can Help</h2>
                <p>WYN Technologies specialists are dedicated to providing the best cabling solutions for your home or business! Our associates are happy to provide insight into best practices when choosing just which voice and data cabling best fits your company. We are proud to follow industry standards and regulations in the cabling of your business, ensuring that you’re always safe and up to code!</p>

                <p>Give our specialists a call today at <a href="tel:3368990555">(336) 899-0555</a>, and we’ll develop an extensive, cost-effective plan for your business network cabling installation!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default PointSaleSolutionsPage
